import { Flex, Spinner, VStack } from '@chakra-ui/react';
import {
  fetchMessages,
  fetchMessagesOnScroll,
  resetShouldScrollToBottom,
} from 'contexts/redux/messenger/messengerSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageBubble } from './MessageBubble';

export const ChatMessagesSection: React.FC = () => {
  const dispatch = useDispatch<any>();
  const {
    messages,
    currentThread,
    messengerLoading,
    uploadLoading,
    page,
    totalPages,
    shouldScrollToBottom,
  } = useSelector((state: RootState) => ({
    messages: state.messenger.messages,
    messengerLoading: state.messenger.loading,
    uploadLoading: state.uploads.loading,
    currentThread: state.messenger.currentThread,
    totalPages: state.messenger.messagePagination.totalPages,
    page: state.messenger.messagePagination.page,
    shouldScrollToBottom: state.messenger.shouldScrollToBottom,
  }));
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isLoading = useMemo(
    () => messengerLoading || uploadLoading,
    [messengerLoading, uploadLoading],
  );

  const fetchMoreMessages = useCallback(() => {
    if (currentThread?.id && page < totalPages) {
      dispatch(
        fetchMessagesOnScroll({
          threadId: currentThread.id,
          sortBy: '-createdAt',
          page: page + 1,
        }),
      );
    }
  }, [page, currentThread?.id, dispatch, totalPages]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current;

    if (container && container.scrollTop === 0 && container.scrollHeight > 0) {
      fetchMoreMessages();
    }
  }, [fetchMoreMessages]);

  useEffect(() => {
    if (currentThread?.id) {
      dispatch(
        fetchMessages({
          threadId: currentThread.id,
          sortBy: '-createdAt',
        }),
      );
    }
  }, [dispatch, currentThread?.id]);

  useEffect(() => {
    if (shouldScrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      dispatch(resetShouldScrollToBottom());
    }
  }, [shouldScrollToBottom, dispatch]);

  return (
    <VStack
      w="100%"
      py={4}
      h="100%"
      spacing={0}
      align="flex-start"
      gap={3}
      ref={containerRef}
      onScroll={handleScroll}
      overflow="scroll">
      {isLoading && (
        <Flex justifyContent="center" align="center" w="100%">
          <Spinner />
        </Flex>
      )}
      {messages &&
        messages?.map((message, index) => {
          const prevMessage = messages[index - 1];

          return (
            <MessageBubble
              index={index}
              key={index}
              message={message}
              previousMessage={prevMessage}
              nextMessage={messages[index + 1]}
            />
          );
        })}
      <Flex ref={messagesEndRef} />
    </VStack>
  );
};
