import { Divider, Flex, Text, VStack } from '@chakra-ui/react';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import { setViewPhotoDialog } from 'contexts/redux/dialog/dialogsSlice';
import { RootState } from 'contexts/redux/store';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IMessage } from 'services/@types';
import _uploadService from 'services/upload.api';
import { updateMessage } from '../../../../../contexts/redux/messenger/messengerSlice';
import { useDecreaseUnreadMessagesCount } from '../hooks/useUnreadMessagesCount';

interface MessageBubbleProps {
  message: IMessage;
  previousMessage: IMessage;
  nextMessage: IMessage;
  index: number;
}
export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  previousMessage,
  nextMessage,
  index,
}) => {
  const user = useMyUser();
  const dispatch = useDispatch<any>();
  const { t } = useTranslation();
  const { mutateAsync: decreaseUnreadMessagesCount } =
    useDecreaseUnreadMessagesCount(message?.threadId);

  const { currentThreadUnreadMessagesId } = useSelector(
    (state: RootState) => state.messenger,
  );

  const showDateDivider = useMemo(
    () =>
      !previousMessage ||
      new Date(message?.createdAt)?.toDateString()?.split('T')[0] !==
        new Date(previousMessage?.createdAt)?.toDateString()?.split('T')[0],
    [message, previousMessage],
  );

  const isMyMessage = useMemo(
    () => message.senderId === user.id,
    [message.senderId, user.id],
  );

  const isUnreadMessageDivider = useMemo(
    () => currentThreadUnreadMessagesId === message?.id,
    [currentThreadUnreadMessagesId, message?.id],
  );

  const handleDownloadFile = useCallback(
    async (item: { url: string; type: string; displayName: string }) => {
      try {
        console.log('download file');

        const response = await _uploadService.getUpload(item.url, 'original');
        console.log('response', response);
        const imageData = URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = imageData;
        a.download = item.displayName;
        a.click();
      } catch (error) {
        console.error(error);
      }
    },
    [],
  );

  const handleMediaClicked = useCallback(
    (item) => {
      dispatch(setViewPhotoDialog({ item: item }));
    },
    [dispatch],
  );

  const handleSeenMessage = useCallback(async () => {
    if (message?.seenBy?.find((seen) => seen.userId === user.id)) {
      console.log('seen');
      return;
    }
    if (message.senderId === user.id) {
      return;
    }

    const { payload } = await dispatch(
      updateMessage({
        id: message.id,
        seenBy: [{ userId: user.id, seenAt: new Date() }],
      }),
    );
    if (payload) {
      // decrease unread messages count
      decreaseUnreadMessagesCount();
    }
    console.log('not seen');
  }, [decreaseUnreadMessagesCount, dispatch, message, user.id]);

  useEffect(() => {
    handleSeenMessage();
  }, [handleSeenMessage]);

  return (
    <VStack
      key={index.toString()}
      w="100%"
      spacing={0}
      gap={2}
      align={isMyMessage ? 'flex-end' : 'flex-start'}
      alignSelf={isMyMessage ? 'flex-end' : 'flex-start'}>
      {showDateDivider && (
        <Flex align="center" w="100%" py={2}>
          <Divider borderColor="#8E7A7080" flex={1} />
          <Text mx={4} fontSize="xs" color="gray.500">
            {moment(message.createdAt).format('MMM DD')}
          </Text>
          <Divider flex={1} borderColor="#8E7A7080" />
        </Flex>
      )}
      {isUnreadMessageDivider && (
        <Flex flexDirection="column" align="center" w="100%" py={2}>
          <Text mx={4} fontSize="xs" color="gray.500">
            {t('messenger.chat.unreadNewMessage')}
          </Text>
          <Divider flex={1} borderColor="#8E7A7080" />
        </Flex>
      )}
      {message.text && (
        <Flex
          bg={isMyMessage ? '#8E7A70' : 'brand.400'}
          p={2}
          maxW={'70%'}
          borderRadius={'md'}>
          <Text
            key={message.threadId}
            fontSize="sm"
            color={isMyMessage ? 'white' : 'gray.900'}>
            {message.text}
          </Text>
        </Flex>
      )}
      {message?.media?.map((item, index) => (
        <Flex role="group" align={'center'} key={index}>
          {/* <Menu variant={'item'}>
            <MenuButton display={item.type === 'image' ? 'block' : 'none'}>
              <AuthImage
                path={item.url}
                w="120px"
                h="120px"
                borderRadius="8px"
              />
            </MenuButton>
            <MenuList sx={{ borderRadius: '16px', minWidth: 'fit-content' }}>
              <MenuItem
                icon={<Icon as={LuDownload} fontSize={18} />}
                onClick={() => {}}>
                Save to device
              </MenuItem>
              <Divider />
              <MenuItem
                icon={<Icon as={LuPaperclip} fontSize={18} />}
                onClick={() => {}}>
                Assign to Event
              </MenuItem>
            </MenuList>
          </Menu> */}
          <Flex
            bg={isMyMessage ? '#8E7A70' : 'brand.400'}
            boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
            p={0}
            mx={2}
            onClick={async () => {
              if (item.type === 'image') {
                handleMediaClicked(item);
              }
              if (item.type === 'document') {
                handleDownloadFile(item);
              }
            }}
            cursor="pointer"
            borderRadius={item.type === 'image' ? 'md' : '3xl'}>
            {item.type === 'image' && (
              <AuthImage
                path={item.url}
                key={index}
                id={index.toString()}
                onClick={() => handleMediaClicked(item)}
                transition="all 0.2s"
                _hover={{ cursor: 'pointer', opacity: 0.8 }}
                src={item.url}
                alt={''}
                borderRadius={'md'}
                maxW={120}
                maxH={120}
              />
            )}
            {item.type !== 'image' && (
              <Flex borderRadius={'3xl'} py={2} px={4}>
                <Text>{item.displayName}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      ))}
      {/* {message.error && (
        <HStack spacing={1} px={2}>
          <Icon as={LuInfo} color="red.500" fontSize={12} />
          <Text
            color="red.500"
            fontSize="xs"
            fontWeight={400}
            display={message.error ? 'block' : 'none'}>
            {t('messenger.chat.error')}
          </Text>
        </HStack>
      )} */}
      <Text
        // display={!message.error ? 'block' : 'none'}
        display={'block'}
        px={1}
        fontSize="xs"
        fontWeight={400}
        color="gray.500">
        {moment(message.createdAt).format('hh:mm')}
      </Text>
    </VStack>
  );
};
