import { HStack, IconButton, Image, Input, VStack } from '@chakra-ui/react';

import attachmentIcon from 'assets/svg/messenger/attachment-icon.svg';
import imageAddIcon from 'assets/svg/messenger/image-add-icon.svg';
import sendIcon from 'assets/svg/messenger/no-messages-chat-icon.svg';
import TextField from 'components/fields/TextField';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import {
  createMessage,
  createThread,
  updateLastThread,
} from 'contexts/redux/messenger/messengerSlice';
import { RootState } from 'contexts/redux/store';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ResizeTextarea from 'react-textarea-autosize';
import { IEvent, Upload, User } from 'services/@types';
import _uploadService from 'services/upload.api';
import { resetShouldScrollToBottom } from '../../../../../contexts/redux/messenger/messengerSlice';

export const ChatBottomSection: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<any>();
  const [message, setMessage] = useState<string>('');
  const myUser = useMyUser();
  const imgInputRef = useRef(null);
  const fileInputRef = useRef(null);

  const { newThread, currentThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));

  // disable for creation of new thread
  const isDisabled = useMemo(() => {
    // check if empty object
    return newThread !== null && Object.keys(newThread).length === 0;
  }, [newThread]);

  const handleChangeFile = async (input: any, type: 'image' | 'document') => {
    const file = input?.target?.files[0];
    if (!file) return;

    // Set max size based on file type
    let maxSize = 10 * 1024 * 1024; // 10MB for images

    if (type === 'document') {
      maxSize = 15 * 1024 * 1024; // 15MB for files
    }
    // Check file size
    if (file?.size > maxSize) {
      input.value = ''; // Clear the input
      return;
    }

    try {
      const newImg: Upload = await _uploadService.uploadFile(
        'thread',
        currentThread?.id,
        'public',
        file,
      );
      if (newImg) {
        const newMessage = await dispatch(
          createMessage({
            media: [
              {
                type: type,
                url: newImg.filePath,
                displayName: newImg.fileName,
              },
            ],
            senderId: myUser?.id as string,
            threadId: currentThread.id,
          }),
        );
        if (newMessage?.payload) {
          console.log('File uploaded');
          setMessage('');
        }
      }
    } catch (error) {
      console.error('use upload file error ->', error);
    }
  };

  const sendMessages = useCallback(async () => {
    // send message for current thread
    if (currentThread) {
      const newMessage = await dispatch(
        createMessage({
          text: message,
          senderId: myUser?.id as string,
          threadId: currentThread.id,
        }),
      );

      if (newMessage.payload) {
        console.log('Message created');
        console.log(newMessage.payload);
        setMessage('');
      }
      return;
    }
    // create thread if not exists
    if (newThread?.participants) {
      const body: any = {
        participants: [
          ...(newThread.participants as User[]).map(
            (user: User) => user?.id as string,
          ),
          myUser?.id,
        ],
      };

      if (newThread?.title) {
        body.title = newThread.title;
      }
      if (newThread?.event?.id) {
        body.eventId = newThread.event.id;
      }
      const newThreadResult = await dispatch(createThread(body));

      if (newThreadResult.payload) {
        console.log('Thread created');

        const newMessage = await dispatch(
          createMessage({
            text: message,
            senderId: myUser?.id as string,
            threadId: newThreadResult?.payload?.id,
          }),
        );

        if (newMessage.payload) {
          console.log('Message created');
          // update last thread
          const updates: { event?: IEvent } = {
            // recipient: newThread.participants[0] as User,
          };
          if (newThread.event) {
            updates['event'] = newThread.event;
          }
          dispatch(updateLastThread(updates));
          setMessage('');
        }
      }
      return;
    }
  }, [newThread, myUser?.id, message, dispatch, currentThread]);

  return (
    <VStack w="100%" h="fit-content" gap={5} spacing={0}>
      <HStack w="100%" spacing={0} gap={2} align="end">
        <TextField
          pt={3}
          w={'100%'}
          maxH={'140px'}
          minH={'50px'}
          resize="none"
          iconPointerEvents="auto"
          disabled={isDisabled}
          textAreaAs={ResizeTextarea}
          value={message}
          onChange={(e: any) => {
            setMessage(e.target.value);
          }}
          iconRight={
            <IconButton
              aria-label="send button"
              p={1}
              transform={
                i18n.dir() === 'rtl' ? 'rotateZ(-90deg)' : 'rotateZ(0deg)'
              }
              disabled={isDisabled}
              variant="actionIconTransparent"
              icon={<Image src={sendIcon} />}
              onClick={sendMessages}
            />
          }
          placeholder={t('messenger.chat.input_placeholder')}
        />
        <IconButton
          pb={4}
          icon={<Image src={attachmentIcon} />}
          variant="actionIconTransparent"
          aria-label={'new message'}
          disabled={isDisabled}
          onClick={() => {
            console.log('click');
            console.log(fileInputRef);
            fileInputRef.current?.click();
          }}
        />
        <Input
          type="file"
          accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
          display="none"
          onChange={(e: any) => handleChangeFile(e, 'document')}
          ref={fileInputRef}
        />
        <IconButton
          pb={4}
          icon={<Image src={imageAddIcon} />}
          variant="actionIconTransparent"
          aria-label={'new message'}
          disabled={isDisabled}
          onClick={() => {
            console.log('click');
            console.log(imgInputRef);
            imgInputRef.current?.click();
          }}
        />
        <Input
          type="file"
          accept="image/*"
          display="none"
          onChange={(e: any) => handleChangeFile(e, 'image')}
          ref={imgInputRef}
        />
      </HStack>
    </VStack>
  );
};
