// Chakra imports
import {
  Box,
  Flex,
  Portal,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
// import Footer from 'components/footer/FooterAdmin';
// Layout components
import Fab from 'components/fab';
import Navbar from 'components/navbar/NavbarAdmin';
import ScrollToTop from 'components/scrollToTop';
import Sidebar from 'components/sidebar/Sidebar';
import {
  fetchUser,
  setCredentials,
  useAuthToken,
  useMyUser,
} from 'contexts/redux/auth/authSlice';
import { fetchBusinessCategories } from 'contexts/redux/businessCategories/businessCategoriesSlice';
import { fetchBusinessTags } from 'contexts/redux/businessTags/businessTagsSlice';
import { setRequestQuoteDialog } from 'contexts/redux/dialog/dialogsSlice';
import { SidebarContext } from 'contexts/SidebarContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from 'routes';
import PushNotificationAlert from '../../components/pushNotificationAlert';

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const { i18n } = useTranslation();
  const wrapperRef = useRef<any>();
  const navigate = useNavigate();
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mini, setMini] = useState(false);
  const dispatch = useDispatch<any>();
  const [hovered, setHovered] = useState(false);
  const token = useAuthToken();
  const user = useMyUser();
  // functions for changing the states from components

  useEffect(() => {
    const _userWithTokens = localStorage.getItem('token');
    if (_userWithTokens) {
      const parsedUserWithTokens = JSON.parse(_userWithTokens);
      dispatch(setCredentials(parsedUserWithTokens));
      dispatch(fetchUser(parsedUserWithTokens.user.id));
      dispatch(fetchBusinessTags({}));
      dispatch(fetchBusinessCategories({}));

      const requestQuoteToken = localStorage.getItem('requestQuoteToken');
      if (requestQuoteToken) {
        dispatch(setRequestQuoteDialog({}));
      }
    } else {
      navigate('/auth/get-started');
    }
  }, [dispatch, navigate, token]);

  useEffect(() => {
    if (user && user.onboardingStep !== 'completed') {
      navigate('/auth/sign-up');
    }
  }, [navigate, user]);
  const getActiveRoute = useCallback((routes: RoutesType[]): string => {
    for (let i = 0; i < routes.length; i++) {
      const r: { items?: any; layout?: string; path: string; name: string } =
        routes[i];

      if (r.items) {
        const rt = r.items?.find((item: any) => {
          if (
            item.path.includes('/main/event/') &&
            item.path.includes('/messenger/')
          ) {
            return (
              window.location.pathname.includes(item.layout) &&
              window.location.pathname.includes('/main/event/') &&
              window.location.pathname.includes('/messenger/')
            );
          }
          if (item.path.includes('update') && !item.path.includes(':id')) {
            return window.location.pathname.includes(item.layout + item.path);
          }
          if (item.path.includes(':id') && !item.path.includes('/messenger')) {
            const replace = item.path.replace(':id', '');
            return window.location.pathname.includes(item.layout + replace);
          }
          return item.layout + item.path === window.location.pathname;
        });

        if (rt) {
          return rt.name;
        }
      }

      if (
        r?.layout &&
        r?.path &&
        r?.layout + r?.path === window.location.pathname
      ) {
        return r.name;
      }

      if (
        r?.layout &&
        r?.path &&
        r?.path.includes(':id') &&
        r?.path.includes(':eventId') &&
        window.location.pathname.includes('/main/event/') &&
        window.location.pathname.includes('/messenger/')
      ) {
        return r.name;
      }

      if (
        r?.layout &&
        r?.path &&
        r?.path.includes(':id') &&
        window.location.pathname.includes(r.layout + r.path.replace(':id', ''))
      ) {
        return r.name;
      }
    }
    return '';
  }, []);

  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i]?.collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((route: RoutesType, key: number) => {
      if (route.layout === '/main') {
        const animatedComponent = (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              style={{ width: '100%', paddingBottom: '40px' }}
              transition={{ duration: 0.5 }}>
              {route.component}
            </motion.div>
          </AnimatePresence>
        );
        return (
          <Route path={`${route.path}`} element={animatedComponent} key={key} />
        );
      }
      if (route.collapse) {
        return getRoutes(route.items);
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = i18n.dir();
  const { onOpen } = useDisclosure();
  const bg = useColorModeValue('background.100', 'background.900');

  // hook for push notifications
  return (
    <Box bg={bg} h="100vh" w="100vw">
      <PushNotificationAlert />
      <ScrollToTop wrapper={wrapperRef} />
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}>
        <Sidebar
          hovered={hovered}
          setHovered={setHovered}
          mini={mini}
          routes={routes}
          display="none"
          {...rest}
        />
        <Flex
          float={i18n.dir() === 'rtl' ? 'left' : 'right'}
          minHeight="100vh"
          height="100%"
          ref={wrapperRef}
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: '100%', xl: 'calc( 100% - 260px )' }}
          maxWidth={{ base: '100%', xl: 'calc( 100% - 260px )' }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
          bg="warmWhite.100">
          <Portal>
            <Box>
              <Navbar
                hovered={hovered}
                setMini={setMini}
                mini={mini}
                onOpen={onOpen}
                logoText={'Brilliant Events CRM'}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                theme={props.theme}
                setTheme={props.setTheme}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          <Flex
            mx="auto"
            key={window.location.pathname}
            px={{ base: '20px', sm: 0, md: '40px' }}
            pe="20px"
            display="flex"
            w="100%"
            minH="100vh"
            pt="50px">
            <Routes>{getRoutes(routes)}</Routes>
          </Flex>

          {/* <Box>
            <Footer />
            </Box> */}
          <Fab />
        </Flex>
      </SidebarContext.Provider>
    </Box>
  );
}
