// Chakra imports
import { Flex, useColorModeValue, VStack } from '@chakra-ui/react';
import { useEffect } from 'react';
// Assets
import { Supplier } from 'services/@types';
import { NotifyAlertDialog } from '../../../../../components/pushNotificationAlert';
import { messaging, onMessage } from '../../../../../config/firebase';
import { ChatBottomSection } from './ChatBottomSection';
import { ChatMessagesSection } from './ChatMessagesSection';
import { ChatTopHeader } from './ChatTopHeader';

export default function ChatComponent(props: {
  status?: string;
  onEditSupplier?: (supplier: Supplier) => void;
  onDeleteSupplier?: (supplier: Supplier) => void;
  onClose?: () => void;
  [x: string]: any;
}) {
  const { status, onClose, ...rest } = props;

  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');

  return (
    <Flex
      flexDirection="column"
      {...rest}
      px={6}
      h="100%"
      zIndex={2}
      w="100%"
      display="flex"
      position={'relative'}>
      <VStack
        h="100%"
        w="100%"
        display="flex"
        position={'relative'}
        borderColor={borderColor}
        align="center"
        gap={{ sm: 1, md: 0 }}>
        <ChatTopHeader />

        {/* <NoMessagesChat /> */}
        <ChatMessagesSection />
        <ChatBottomSection />
      </VStack>
    </Flex>
  );
}
