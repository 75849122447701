// Chakra imports
import {
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import AuthImage from 'components/authImage/AuthImage';
import { useMyUser } from 'contexts/redux/auth/authSlice';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Thread } from 'services/@types';
import UserService from 'services/user.api';
import FavoriteIcon from '../../../../../assets/img/favorite/favorite.png';
import { PiImageSquareBold } from 'react-icons/pi';
import { ImAttachment } from 'react-icons/im';
import { useUnreadMessagesCount } from '../hooks/useUnreadMessagesCount';
export default function ThreadListItem(props: {
  thread: Thread;
  onSendInvite?: () => void;
  [x: string]: any;
}) {
  const { thread, onSendInvite, ...rest } = props;
  const user = useMyUser();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const { t } = useTranslation();
  const { data: threadUnreadCountData } = useUnreadMessagesCount(
    thread?.id,
    thread?.lastMessage?.id,
  );
  const secondaryTextColor = useColorModeValue('primary.100', 'whiteAlpha.600');

  const { i18n } = useTranslation();
  const memoIsFavorite = false;

  const memoizedTimestamp = useMemo(() => {
    // check if today
    if (moment().isSame(thread?.lastMessage?.createdAt, 'day')) {
      return moment(thread?.lastMessage?.createdAt).format('LT');
    } else {
      return moment(thread?.lastMessage?.createdAt).format('DD MMM');
    }
  }, [thread?.lastMessage?.createdAt]);

  const lastMessage = useMemo(() => {
    if (thread?.lastMessage?.media?.length > 0) {
      if (
        thread?.lastMessage?.media?.find((media) => media?.type === 'image')
      ) {
        return t('messenger.chat.image');
      }

      return t('messenger.chat.attachment');
    }
    return thread?.lastMessage?.text;
  }, [thread?.lastMessage?.text, t]);

  const lastMessageIcon = useMemo(() => {
    if (thread?.lastMessage?.media?.length > 0) {
      if (
        thread?.lastMessage?.media?.find((media) => media?.type === 'image')
      ) {
        return (
          <Icon as={PiImageSquareBold} w="24px" h="24px" color="#8E7A70" />
        );
      }

      return (
        <Icon
          as={ImAttachment}
          w="17px"
          h="24px"
          color="#8E7A70"
          transform="rotate(-45deg)"
        />
      );
    }
    return null;
  }, [thread?.lastMessage?.media]);

  const participant = useMemo(
    () => (thread?.participants as string[])?.find((p) => p !== user?.id) || '',
    [thread?.participants, user?.id],
  );

  const participantResult = useQuery({
    queryKey: [`getParticipant-${participant}`],
    queryFn: () => UserService.getUser(participant),
    enabled: !!participant,
  });

  const avatarPath = useMemo(() => {
    return (
      participantResult.data?.media?.find((media) => media?.type === 'avatar')
        ?.url || ''
    );
  }, [participantResult?.data]);

  const name = useMemo(() => {
    return participantResult?.data?.firstName &&
      participantResult?.data?.lastName
      ? participantResult?.data?.firstName +
          ' ' +
          participantResult?.data?.lastName
      : '';
  }, [participantResult?.data]);

  return (
    <Flex
      w="100%"
      py={4}
      ps={2}
      pe={'18px'}
      transition="all 0.2s"
      gap={7}
      flex={1}
      borderRadius="8px"
      bg="#F5F9FF"
      position="relative"
      width="calc(100% - 34px)"
      cursor="pointer"
      {...rest}
      _hover={{ bg: '#93C3B31A' }}
      align="center">
      <Flex pos="relative" alignSelf={'start'}>
        <Image
          alt=""
          src={FavoriteIcon}
          pos="absolute"
          w="25px"
          h="25px"
          zIndex="20"
          visibility={memoIsFavorite ? 'visible' : 'hidden'}
        />
        <AuthImage
          name={name}
          isAvatar
          path={avatarPath}
          h={{ base: '60px', sm: '52px', md: '60px' }}
          w={{ base: '60px', sm: '52px', md: '60px' }}
          borderRadius="full"
        />
        <Flex
          w={'14px'}
          h={'14px'}
          bottom={0}
          right={0}
          bg="brand.600"
          borderRadius="full"
          border="2px solid #fff"
          position="absolute"
        />
      </Flex>

      <Flex direction="column" flex={1} position="relative">
        <VStack align="start" spacing={0} flex={1} wordBreak={'break-all'}>
          <HStack w="100%">
            <Text
              color={textColor}
              fontSize="xl"
              flex={1}
              noOfLines={1}
              fontWeight="700">
              {thread.title || name}
            </Text>
            {threadUnreadCountData &&
              threadUnreadCountData?.unreadMessagesCount > 0 && (
                <Text
                  minW={6}
                  h={6}
                  px={1}
                  textAlign="center"
                  borderRadius={12}
                  color="#fff"
                  bg="#5FBF96">
                  {threadUnreadCountData?.unreadMessagesCount}
                </Text>
              )}
          </HStack>

          <Text
            color={textColor}
            fontSize="sm"
            flex={1}
            noOfLines={1}
            fontWeight="700">
            {thread.title ? name : ''}
          </Text>
          <HStack
            display={lastMessage?.length > 0 ? 'flex' : 'none'}
            wordBreak={'break-all'}>
            {lastMessageIcon}
            <Text
              noOfLines={1}
              flex={1}
              maxW={150}
              fontSize={{ base: 'sm', md: 'lg' }}>
              {lastMessage}
            </Text>
          </HStack>
        </VStack>
      </Flex>
      <Text
        color={secondaryTextColor}
        fontSize={{ base: 'xs', md: 'sm' }}
        fontWeight="500"
        bottom={4}
        right={i18n.dir() === 'rtl' ? 'auto' : 2}
        left={i18n.dir() === 'rtl' ? 2 : 'auto'}
        position={'absolute'}
        noOfLines={1}>
        {memoizedTimestamp}
      </Text>
    </Flex>
  );
}
