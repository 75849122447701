import { Flex, Spinner, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import KeepHistory from './keepHistory';
import SettingItem from './settingItem';
import {
  useMyUserPreferences,
  fetchMyUserPreferences,
  updateUserPreferences,
} from 'contexts/redux/userPreferences/userPreferencesSlice';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IUserNotificationMethods, UserPreferences } from 'services/@types';
import { RootState } from 'contexts/redux/store';
import { useTranslation } from 'react-i18next';
interface SettingItemProps {
  title: string;
  value: IUserNotificationMethods;
  keyName: string;
}
export default function NotificationSettings() {
  const userPreferences = useMyUserPreferences();
  const loading = useSelector(
    (state: RootState) => state.userPreferences.loading,
  );
  const dispatch = useDispatch<any>();
  const toast = useToast();
  const { t } = useTranslation();

  const firstRow: SettingItemProps[] = useMemo(
    () => [
      {
        title: 'new_event_notifications',
        value: userPreferences?.notificationPreferences.newEvent,
        keyName: 'newEvent',
      },
      {
        title: 'task_notifications',
        value: userPreferences?.notificationPreferences.task,
        keyName: 'task',
      },
    ],
    [
      userPreferences?.notificationPreferences?.task,
      userPreferences?.notificationPreferences?.newEvent,
    ],
  );

  const secondRow: SettingItemProps[] = useMemo(
    () => [
      {
        title: 'new_meeting_notifications',
        value: userPreferences?.notificationPreferences.newMeeting,
        keyName: 'newMeeting',
      },
      {
        title: 'system_notifications',
        value: userPreferences?.notificationPreferences.system,
        keyName: 'system',
      },
    ],
    [
      userPreferences?.notificationPreferences?.newMeeting,
      userPreferences?.notificationPreferences?.system,
    ],
  );

  const handleUpdateMyUserPreferences = useCallback(
    async (newPreferences: Partial<UserPreferences>) => {
      const updatedData = await dispatch(
        updateUserPreferences({
          id: userPreferences?.id,
          updates: newPreferences,
        }),
      );

      if (updatedData?.payload && !updatedData?.error) {
        toast({
          title: t('toast.title.success'),
          description: t('toast.description.notificaion_settings_updated'),
          status: 'success',
          duration: 1000,
          variant: 'main',
        });
      }
    },
    [, userPreferences?.id, dispatch, toast],
  );

  useEffect(() => {
    if (!userPreferences?.id) {
      dispatch(fetchMyUserPreferences());
    }
  }, [dispatch, userPreferences?.id]);

  return (
    <Flex w="100%" pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card minH="calc(100vh - 170px)" h="auto" w="100%" p="36px" gap={6}>
        {loading && (
          <Flex alignItems="center" justifyContent="center" h="100%" w="100%">
            <Spinner size="xl" />
          </Flex>
        )}
        {!loading && (
          <>
            <Flex wrap="wrap" gap="60px">
              {firstRow.map((item) => (
                <SettingItem
                  key={item.title}
                  title={item.title}
                  value={item.value}
                  keyName={item.keyName}
                  handleUpdateMyUserPreferences={handleUpdateMyUserPreferences}
                />
              ))}
            </Flex>
            <Flex wrap="wrap" gap="60px">
              {secondRow.map((item) => (
                <SettingItem
                  key={item.title}
                  title={item.title}
                  value={item.value}
                  keyName={item.keyName}
                  handleUpdateMyUserPreferences={handleUpdateMyUserPreferences}
                />
              ))}
            </Flex>
            <Flex wrap="wrap" gap="60px">
              <SettingItem
                title={'quote_request_notifications'}
                keyName={'quoteRequest'}
                handleUpdateMyUserPreferences={handleUpdateMyUserPreferences}
                value={userPreferences?.notificationPreferences.quoteRequest}
              />
              <KeepHistory
                notificationHistoryDuration={
                  userPreferences.notificationHistoryDuration
                }
                handleUpdateMyUserPreferences={handleUpdateMyUserPreferences}
              />
            </Flex>
          </>
        )}
      </Card>
    </Flex>
  );
}
