// Chakra imports
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  Flex,
  HStack,
  Icon,
  IconButton,
  List,
  ListItem,
  Show,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
// Assets
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// Custom components
import Card from 'components/card/Card';
import { RootState } from 'contexts/redux/store';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import Conversations from 'views/admin/main/others/messages/components/Conversations';
import { useQuery } from '@tanstack/react-query';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import {
  clearMessages,
  setCurrentThread,
  setNewThread,
} from 'contexts/redux/messenger/messengerSlice';
import { AnimatePresence, motion } from 'framer-motion';
import useThreads from 'hooks/useThreads';
import { BsThreeDots } from 'react-icons/bs';
import { FiEdit3 } from 'react-icons/fi';
import { IoMdArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import { Thread } from 'services/@types';
import EventService from 'services/event.api';
import ChatComponent from './components/ChatComponent';
import NoConversationFound from './components/NoConversationFound';
import NoMessagesYet from './components/NoMessagesYet';
import { ThreadListItemComponent } from './components/ThreadListItemComponent';

export default function Messenger() {
  // Chakra Color Mode
  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const params = useParams();

  const [search, setSearch] = useState<string>('');

  const { currentThread, newThread } = useSelector((state: RootState) => ({
    newThread: state.messenger.newThread,
    currentThread: state.messenger.currentThread,
  }));

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t, i18n } = useTranslation();
  const [introStartConversation, setIntroStartConversation] = useState(false);

  const dispatch = useDispatch<any>();

  const isEventMessages = useMemo(() => !!params?.eventId, [params]);

  const eventResult = useQuery({
    queryKey: [`getEvent-${params?.eventId}`],
    queryFn: () => EventService.getEvent(params?.eventId),
    enabled: !!params?.eventId,
  });

  const eventData = useMemo(() => eventResult?.data, [eventResult?.data]);

  const query = useMemo(() => {
    const query: { name?: string; limit: number; eventId?: string } = {
      limit: 100,
    };
    if (search) {
      query['name'] = search;
    }
    if (params?.eventId) {
      query['eventId'] = params?.eventId;
    }
    return query;
  }, [search, params?.eventId]);

  const queryKey = useMemo(() => {
    if (params?.eventId && search) {
      return [`fetchEventThreads-${params?.eventId}-${search}`];
    }
    if (params?.eventId) {
      return [`fetchEventThreads-${params?.eventId}`];
    }
    if (search) {
      return [`fetchThreads-${search}`];
    }
    return ['fetchThreads'];
  }, [search, params?.eventId]);

  const { threads } = useThreads({
    query,
    queryKey,
  });

  useEffect(() => {
    if (threads && params?.id) {
      const thread = threads?.find((t: Thread) => t.id === params?.id);
      if (thread) {
        dispatch(setCurrentThread(thread));
      }
    }
  }, [threads, params?.id, dispatch]);

  useEffect(() => {
    if (!isOpen && (currentThread || newThread)) {
      onOpen();
    }
    if (isOpen && !currentThread && !newThread) {
      onClose();
    }
  }, [currentThread, newThread, isOpen, onClose, onOpen]);

  if (search === '' && threads.length === 0 && !introStartConversation) {
    return (
      <>
        <NoMessagesYet
          onStartConversation={() => setIntroStartConversation(true)}
        />
      </>
    );
  }

  return (
    <Flex
      w="100%"
      pt={{ base: '130px', md: '80px', xl: '80px' }}
      flexDirection="column"
      h="100%"
      gap={4}>
      <Flex
        h="100%"
        flexDirection={{ base: 'column', lg: 'row' }}
        gap={{ base: '20px', xl: '20px' }}>
        <Card
          p="0px"
          h="100%"
          display={'flex'}
          overflow="scroll"
          px="0px"
          borderRadius={{ base: '0px', md: '30px' }}
          pt="30px"
          pb="30px"
          w="100%"
          maxW={{ base: '100%', lg: '400px' }}>
          {/* <Conversations onOpenMessages={onOpen} /> */}
          <HStack w="100%" px={6} mb={6}>
            {isEventMessages ? (
              <Flex alignItems="center">
                <IconButton
                  icon={<IoMdArrowBack />}
                  variant="actionIconTransparent"
                  aria-label={'new message'}
                  onClick={() => navigate(-1)}
                />
                <Text fontSize="xl" fontWeight="bold">
                  {eventData?.name}
                </Text>
              </Flex>
            ) : (
              <Text fontSize="xl" fontWeight="bold">
                {t('messenger.title')}
              </Text>
            )}

            <Spacer />
            <IconButton
              icon={<BsThreeDots color="#8E7A70" />}
              variant="actionIconTransparent"
              aria-label={'new message'}
            />
            <IconButton
              variant="actionIconTransparent"
              aria-label={'new message'}
              icon={
                <Icon as={FiEdit3} width="24px" height="24px" color="#8E7A70" />
              }
              onClick={() => {
                // open new chat
                dispatch(setNewThread({}));
                dispatch(setCurrentThread(null));
                dispatch(clearMessages());
              }}
            />
          </HStack>

          <Flex
            px={6}
            display={
              search.length > 0 || threads.length !== 0 ? 'flex' : 'none'
            }>
            <SearchBar
              placeholder={t('messenger.search_placeholder')}
              onChange={(e: any) => setSearch(e.target.value)}
              value={search}
              w="100%"
            />
          </Flex>
          <Flex px={8} my={6} display={threads.length !== 0 ? 'flex' : 'none'}>
            <Tabs variant="task" isLazy={true} height="100%" overflowY="auto">
              <TabList gap={9}>
                <Tab>{t('messenger.tab.all')}</Tab>
                <Tab>{t('messenger.tab.unread')}</Tab>
              </TabList>
            </Tabs>
          </Flex>
          <List overflow={'scroll'}>
            {threads?.map((thread, key: number) => (
              <ListItem key={thread?.id || key}>
                <ThreadListItemComponent
                  onOpen={() => {
                    dispatch(setCurrentThread(thread));
                    dispatch(setNewThread(null));
                  }}
                  currentThread={thread as Thread}
                />
              </ListItem>
            ))}
          </List>
          <VStack display={threads.length === 0 ? 'flex' : 'none'}>
            <NoConversationFound />
          </VStack>
        </Card>
        <Show above="md">
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0, scale: 0.98 }}
              animate={{ opacity: 1, scale: 1 }}
              style={{
                width: '100%',
                height: '100%',
              }}
              exit={{ opacity: 0, scale: 0.98 }}
              transition={{ duration: 0.15 }}>
              <Card
                display={{
                  base: 'none',
                  lg: isOpen ? 'flex' : 'none',
                }}
                px="0px"
                w="100%"
                h="100%">
                <ChatComponent />
              </Card>
            </motion.div>
          </AnimatePresence>
        </Show>
        <Show below="lg">
          <Drawer
            // styleConfig={{
            //   overlay: {
            //     zIndex: 3,
            //   },
            // }}
            // isFullHeight={false}
            isOpen={!!(currentThread || newThread)}
            onCloseComplete={() => {
              dispatch(setCurrentThread(null));
            }}
            placement={i18n.dir() === 'rtl' ? 'right' : 'left'}
            onClose={() => {
              onClose();
            }}>
            <DrawerContent
              maxW="full"
              mt={{
                sm: 180,
                md: 200,
              }}
              padding={0}
              borderRadius="0px"
              bg={sidebarBackgroundColor}>
              <DrawerBody px="0px" zIndex={0}>
                {/* messenger chat component here */}
                <ChatComponent />
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Show>
      </Flex>
    </Flex>
  );
}
