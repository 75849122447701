import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IMessage, Thread } from 'services/@types/index';
import messageService from 'services/message.api';
import threadService from 'services/thread.api';

export interface MessengerState {
  threads: Thread[];
  eventThreads: Thread[];
  messages: IMessage[];
  currentThread: Thread | null;
  currentThreadUnreadMessagesId: string | null;
  newThread: Thread | null;
  loading: boolean;
  error: string | null;
  messagePagination: {
    page: number;
    limit: number;
    totalPages: number;
  };
  shouldScrollToBottom: boolean;
  newMessageReceived: IMessage | null;
}

const initialState: MessengerState = {
  currentThread: null,
  currentThreadUnreadMessagesId: null,
  newThread: null,
  threads: [],
  eventThreads: [],
  messages: [],
  messagePagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
  },
  loading: false,
  error: null,
  shouldScrollToBottom: false,
  newMessageReceived: null,
};

export const createThread = createAsyncThunk(
  'messenger/createThread',
  async (thread: Thread) => {
    const response = await threadService.createThread(thread);
    return response;
  },
);

export const fetchThreads = createAsyncThunk(
  'messenger/fetchThreads',
  async (params: Record<string, any>) => {
    const response = await threadService.getThreads(params);
    return response;
  },
);

export const fetchEventThreads = createAsyncThunk(
  'messenger/fetchEventThreads',
  async (params: Record<string, any>) => {
    const response = await threadService.getThreads(params);
    return response;
  },
);

export const fetchThread = createAsyncThunk(
  'messenger/fetchThread',
  async (threadId: string) => {
    const response = await threadService.getThread(threadId);
    return response;
  },
);

export const updateThread = createAsyncThunk(
  'messenger/updateThread',
  async ({
    threadId,
    thread,
  }: {
    threadId: string;
    thread: Partial<Thread>;
  }) => {
    const response = await threadService.updateThread(threadId, thread);
    return response;
  },
);

export const deleteThread = createAsyncThunk(
  'messenger/deleteThread',
  async (threadId: string) => {
    await threadService.deleteThread(threadId);
    return threadId;
  },
);

export const fetchMessages = createAsyncThunk(
  'messenger/fetchMessages',
  async (queryParams?: Record<string, any>) => {
    const response = await messageService.getMessages(queryParams);
    return response;
  },
);

export const fetchMessagesOnScroll = createAsyncThunk(
  'messenger/fetchMessagesOnScroll',
  async (params: Record<string, any>) => {
    const response = await messageService.getMessages(params);
    return response;
  },
);

export const createMessage = createAsyncThunk(
  'messenger/createMessage',
  async (message: IMessage) => {
    const response = await messageService.createMessage(message);
    return response;
  },
);

export const updateMessage = createAsyncThunk(
  'messenger/updateMessage',
  async (message: Partial<IMessage>) => {
    const messageId = message.id;
    delete message.id;
    const response = await messageService.updateMessage(messageId, message);
    // invalidate query client for the thread
    return response;
  },
);

export const deleteMessage = createAsyncThunk(
  'messenger/deleteMessage',
  async (messageId: string) => {
    await messageService.deleteMessage(messageId);
    return messageId;
  },
);

export const onNewMessageReceived = createAsyncThunk(
  'messenger/onNewMessageReceived',
  async (messageId: string) => {
    const message = await messageService.getMessage(messageId);
    return message;
  },
);

const messengerSlice = createSlice({
  name: 'messenger',
  initialState,
  reducers: {
    setNewThread: (state, action) => {
      state.newThread = action.payload;
    },
    setNewThreadTitle: (state, action) => {
      state.newThread.title = action.payload;
    },
    setNewThreadEvent: (state, action) => {
      state.newThread.event = action.payload;
    },
    setCurrentThread: (state, action) => {
      state.currentThread = action.payload;
    },
    setCurrentThreadTitle: (state, action) => {
      state.currentThread.title = action.payload;
    },
    setCurrentThreadEvent: (state, action) => {
      state.currentThread.event = action.payload;
    },
    updateLastThread: (state, action) => {
      state.threads[state.threads.length - 1] = {
        ...state.threads[state.threads.length - 1],
        ...action.payload,
      };
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setThreads: (state, action) => {
      state.threads = action.payload;
    },
    resetShouldScrollToBottom: (state) => {
      state.shouldScrollToBottom = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThreads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThreads.fulfilled, (state, action) => {
      state.loading = false;
      state.threads = action.payload.results;
    });
    builder.addCase(fetchThreads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThread.fulfilled, (state, action) => {
      state.loading = false;
      state.currentThread = action.payload;
    });
    builder.addCase(fetchThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateThread.fulfilled, (state, action) => {
      state.loading = false;
      const updatedThread = {
        ...state.currentThread,
        ...action.payload,
      };
      state.currentThread = updatedThread;
      state.threads = state.threads.map((thread) =>
        thread.id === action.payload.id ? updatedThread : thread,
      );
    });
    builder.addCase(updateThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteThread.fulfilled, (state, action) => {
      state.loading = false;
      state.threads = state.threads.filter(
        (thread) => thread.id !== action.payload,
      );
    });
    builder.addCase(deleteThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMessages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMessages.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = action.payload.results.reverse();
      state.messagePagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
      };
      state.shouldScrollToBottom = true;
      const unreadMessage = action.payload.results.find(
        (message) =>
          !message.seenBy?.find((seen) => seen.userId !== message.senderId),
      );
      state.currentThreadUnreadMessagesId = unreadMessage?.id || null;
    });
    builder.addCase(fetchMessages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.messages.push(action.payload);
      // find the thread and update the last message and move it to the top
      const thread = state.threads.find(
        (thread) => thread.id === action.payload.threadId,
      );
      state.threads = state.threads.filter(
        (thread) => thread.id !== action.payload.threadId,
      );
      state.threads.unshift({
        ...thread,
        lastMessage: action.payload,
      });
      state.shouldScrollToBottom = true;
    });
    builder.addCase(createMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(updateMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.newMessageReceived = action.payload;
      state.messages = state.messages.map((message) =>
        message.id === action.payload.id ? action.payload : message,
      );
    });
    builder.addCase(updateMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteMessage.fulfilled, (state, action) => {
      state.loading = false;
      state.messages = state.messages.filter(
        (message) => message.id !== action.payload,
      );
    });
    builder.addCase(deleteMessage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(createThread.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createThread.fulfilled, (state, action) => {
      state.loading = false;
      state.threads.push({ ...state.newThread, ...action.payload });
      state.messages = [];
    });
    builder.addCase(createThread.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchEventThreads.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEventThreads.fulfilled, (state, action) => {
      state.loading = false;
      state.eventThreads = action.payload.results;
    });
    builder.addCase(fetchEventThreads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(fetchMessagesOnScroll.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMessagesOnScroll.fulfilled, (state, action) => {
      state.loading = false;
      state.messages.unshift(...action.payload.results?.reverse());
      state.messagePagination = {
        page: action.payload.page,
        limit: action.payload.limit,
        totalPages: action.payload.totalPages,
      };
    });
    builder.addCase(fetchMessagesOnScroll.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
    builder.addCase(onNewMessageReceived.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(onNewMessageReceived.fulfilled, (state, action) => {
      state.loading = false;
      state.newMessageReceived = action.payload;
      const threadIndex = state.threads.findIndex(
        (thread) => thread.id === action.payload.threadId,
      );
      if (threadIndex !== -1) {
        const updatedThread = {
          ...state.threads[threadIndex],
          lastMessage: action.payload,
        };
        state.threads.splice(threadIndex, 1);
        state.threads.unshift(updatedThread);
      }
      if (state?.currentThread?.id === action.payload.threadId) {
        state.shouldScrollToBottom = true;
        state.messages.push(action.payload);
      }
    });
    builder.addCase(onNewMessageReceived.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  setNewThread,
  setNewThreadTitle,
  setNewThreadEvent,
  setCurrentThread,
  setCurrentThreadTitle,
  setCurrentThreadEvent,
  updateLastThread,
  clearMessages,
  setThreads,
  resetShouldScrollToBottom,
} = messengerSlice.actions;

export default messengerSlice.reducer;
