import {
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
type SearchBarProps = InputGroupProps & {
  variant?: string;
  background?: string;
  placeholder?: string;
  borderRadius?: string | number;
  inputProps?: InputProps;
  [x: string]: any;
};
export function SearchBar(props: SearchBarProps) {
  // Pass the computed styles into the `__css` prop
  const {
    variant,
    background,
    children,
    placeholder,
    borderRadius,
    inputProps,
    ...rest
  } = props;
  // Chakra Color Mode
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  return (
    <HStack
      w="100%"
      spacing={0}
      bg={background ? background : inputBg}
      borderRadius={borderRadius ? borderRadius : '30px'}>
      <IconButton
        aria-label="search"
        bg="transparent"
        borderRadius="inherit"
        _active={{
          bg: 'inherit',
          transform: 'none',
          borderColor: 'transparent',
        }}
        _hover={{
          background: 'none',
        }}
        _focus={{
          background: 'none',
          boxShadow: 'none',
        }}
        icon={<SearchIcon color={searchIconColor} w="15px" h="15px" />}
      />
      <InputGroup w={{ base: '100%', md: '200px' }} {...rest}>
        <Input
          variant="search"
          fontSize="16px"
          color={inputText}
          h="44px"
          bg="transparent"
          ps={0}
          fontWeight="500"
          _placeholder={{ color: 'gray.400', fontSize: 'sm' }}
          borderRadius={borderRadius ? borderRadius : '30px'}
          placeholder={placeholder ? placeholder : 'Search...'}
          {...inputProps}
        />
      </InputGroup>
    </HStack>
  );
}
